.cart-upsell_container__Xx1CH {
  border-bottom: 1px solid var(--color-divider);
  padding: var(--section-spacing) 0;
}

.cart-upsell_title__mS6_w {
  margin-bottom: var(--spacing-20);
  padding: 0 var(--page-inset);
}

div.cart-upsell_slide__gPTv4 {
  width: 70%;
}

div.cart-upsell_slide__gPTv4:first-child {
    margin-left: var(--page-inset);
  }

div.cart-upsell_slide__gPTv4:last-child {
    margin-right: var(--page-inset);
  }

div.cart_cart__ZDx3R {
  padding-bottom: 0 !important;
}
div.cart_cart__ZDx3R {
  background: var(--color-white);
  width: min(390px, 100vw);
  gap: 0;
}
div.cart_cart__ZDx3R {
      font-family: var(--font-beausite);
      font-size: var(--body1-size);
      line-height: 1.5;
      font-weight: 300;
      letter-spacing: 0;
}

div.menu_menu__xRpJJ {
  background: var(--color-primary);
  color: var(--color-white);

  height: 100%;
  overflow: auto;
}

div.SearchModal_search__ex0Ag {

  -webkit-transform: none !important;

          transform: none !important;

  -webkit-transform: initial !important;

          transform: initial !important;
}

  div.SearchModal_search__ex0Ag {
  padding: 0;
  background: var(--color-white);
  width: 100vw;
  height: 0vh;
  overflow: hidden;
  gap: 0;
}

  div.SearchModal_search__ex0Ag[data-open="true"] {
    height: 100vh;
  }

  @media (min-width: 1000px) {div.SearchModal_search__ex0Ag {
    overflow: hidden;
    height: 0vh;
}

    div.SearchModal_search__ex0Ag[data-open="true"] {
      height: 80vh;
    }

      div.SearchModal_search__ex0Ag[data-open="true"] > * {
        height: 80vh;
        max-height: none;
      }
  }

  div.SearchModal_search__ex0Ag {
      font-family: var(--font-beausite);
      font-size: var(--body1-size);
      line-height: 1.5;
      font-weight: 300;
      letter-spacing: 0;
}

