div.search {
  padding: 0;
  background: var(--color-white);
  width: 100vw;
  height: 0vh;
  overflow: hidden;
  gap: 0;

  transform: unset !important;

  &[data-open="true"] {
    height: 100vh;
  }

  @media (--small) {
    overflow: hidden;
    height: 0vh;

    &[data-open="true"] {
      height: 80vh;

      & > * {
        height: 80vh;
        max-height: none;
      }
    }
  }

  @extend %typo-body1;
}
